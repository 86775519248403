import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/4.jpg)`,
                        }}
                    >
                        <h1>Eliminador de etiquetas termocontraíble</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Zion Tech ofrece dos tipos de removedores de etiquetas termorretráctiles (PVC). Uno
                                consiste en un raspador de etiquetas y un soplador de etiquetas y su capacidad alcanza
                                los 4.000 kg / h. El otro es un dispositivo compacto que puede eliminar etiquetas
                                mediante dos funciones combinadas con desguace y soplado El removedor de etiquetas
                                compacto ocupa menos espacio y tiene una estructura mecánica compacta.
                            </p>
                            <p style={{color: '#000'}}>
                                La hoja y el perno raspador de etiquetas de Zion Tech tienen una vida útil más larga que
                                puede alcanzar hasta 10,000 toneladas de entrada, tiene una alta eficiencia de la tasa
                                de separación de etiquetas y una baja tasa de daños en las botellas. 5% Más que nunca,
                                la nueva generación de cuchillas y pernos tiene una mejor eficiencia de trabajo.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                En Asia, la mayoría de las etiquetas son fundas termocontraíbles de PVC que no se pueden
                                quitar con el prelavado de botellas, es un gran problema en la industria del reciclaje
                                de botellas de PET. El raspador de etiquetas Zion Tech tiene una patente que puede
                                resolver completamente este problema. En primer lugar, los raspadores de etiquetas
                                rasgará las etiquetas por sus cuchillas y pernos, de modo que las etiquetas ya no estén
                                adheridas a la superficie de la botella, en segundo lugar, estas etiquetas estarán
                                completamente separadas por la fuerza del viento ajustable y la diferencia de gravedad
                                entre las botellas y las etiquetas.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                600-4000 kg/h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
